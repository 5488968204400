import axios from "axios"
import { useState } from "react"
import { useEffect } from "react"
import './login.css'

const validateCredentials = async (email, password) => {
    const { data } = await axios.get(`https://apn1-eternal-lemur-34403.upstash.io/get/${email}?_token=AYZjACQgZjA5OTNkYTAtNzM5Mi00OGFhLWE2M2ItMjk4ZDNjZWUwZWNiMWIzZjczODA1YTJiNDc0Mzg1ODRkOGJkMjk4YzExNDE=`)

    if (!data.result) {
        return false
    }

    const json = JSON.parse(data.result)

    if (password !== json.password) {
        return false
    }
    localStorage.setItem('username', json.username)
    localStorage.setItem('login', 'true')
    window.location.replace('/')
    return true
}



export default function Login() {

    const loginHandler = async (e) => {
        e.preventDefault()
        const email = e.target[0].value
        const password = e.target[1].value

        const result = await validateCredentials(email, password)
        if (!result) {
            alert('Invalid Credentials')
        }
    }

    const signUpHandler = async (e) => {
        e.preventDefault()
        const username = e.target[0].value
        const email = e.target[1].value
        const password = e.target[2].value

        try {
            await axios.post(`https://apn1-eternal-lemur-34403.upstash.io/set/${email}?_token=AYZjACQgZjA5OTNkYTAtNzM5Mi00OGFhLWE2M2ItMjk4ZDNjZWUwZWNiMWIzZjczODA1YTJiNDc0Mzg1ODRkOGJkMjk4YzExNDE=`,{
                username:username,
                password:password
            })
            alert("Sign Up Success!")
            window.location.reload()

        } catch (err) {
            alert('Sign Up Error!')
        }
    }


    return (
        <>
            <div>
                <div class="wrapper">
                    <div class="card-switch">
                        <label class="switch">
                            <input type="checkbox" class="toggle" />
                            <span class="slider"></span>
                            <span class="card-side"></span>
                            <div class="flip-card__inner">
                                <div class="flip-card__front">
                                    <div class="title">Log in</div>
                                    <form class="flip-card__form" onSubmit={loginHandler}>
                                        <input class="flip-card__input" name="email" placeholder="Email" type="email" />
                                        <input class="flip-card__input" name="password" placeholder="Password" type="password" />
                                        <button class="flip-card__btn">Let`s go!</button>
                                    </form>
                                </div>
                                <div class="flip-card__back">
                                    <div class="title">Sign up</div>
                                    <form class="flip-card__form" onSubmit={signUpHandler}>
                                        <input class="flip-card__input" placeholder="Name" type="name" pattern="^[a-zA-Z]+$" />
                                        <input class="flip-card__input" name="email" placeholder="Email" type="email" />
                                        <input class="flip-card__input" name="password" placeholder="Password" type="password" />
                                        <button class="flip-card__btn">Confirm!</button>
                                    </form>
                                </div>
                            </div>
                        </label>
                    </div>
                </div>
            </div>
        </>)
}