import axios from "axios"
import { useState } from "react"
import { useEffect } from "react"


export default function Logout() {
    useEffect(() => {
        localStorage.removeItem('login')
        localStorage.removeItem('username')
        window.location.replace('/')
    },[])

    return (
        <>
        </>)
}