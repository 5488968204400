import { AuthContext } from "../auth"
import { useContext, useEffect } from "react"
import './home.css'
import Card from "./hero-card"
import ServiceCard from "./service-card"
import {Heartbeat,FindingHome,Grooming,Baseball} from './icons'

export default function Home() {
    const {name,setName} = useContext(AuthContext)

    return (<>
        <div>
            <section id="hero">
                <div style={{position:"relative"}}>
                    <img src="/dog01.jpg" alt="" />
                    <div className="mobile-hidden overlay"></div>
                </div>
                <div className="text">
                    <h2>To Love Is To <span id="planet">Connect.</span></h2>
                    <p>Finding a permanent home for your loved ones is much closer than you can imagine.</p>
                </div>
            </section>
            <section id="category">
                <Card imgUrl={'/categorydog.jpg'} caption={'Dogs'} />
                <Card imgUrl={'/categorycat.jpg'} caption={'Cats'} />
                <Card imgUrl={'/categoryhamster.jpg'} caption={'Hamster'} />
                <Card imgUrl={'/categoryrabbit.jpg'} caption={'Rabbit'} />
            </section>
            <section id="services">
                <div>
                    <h2>Services</h2>
                    <h4>Our Pets are Always Happy</h4>
                    <p style={{lineHeight:'24px'}}>We bring love to their hearts just as they bring us happiness and joy.</p>
                </div>
                <div>
                    <ServiceCard icon={<FindingHome/>} caption={'Finding Homes'} />
                    <ServiceCard icon={<Baseball/>} caption={'Pet Training'} />
                    <ServiceCard icon={<Heartbeat/>} caption={'Pet Health'} />
                    <ServiceCard icon={<Grooming/>} caption={'Pet Grooming'} />
                </div>
            </section>

            <section id="findpet">
                <h2>Find Your Pets Today!</h2>
                <button>Browse</button>
            </section>

        </div>
    </>)
}