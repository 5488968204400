import axios from 'axios'
import './release.css'


async function uploadImage(file) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", file.type);

    var requestOptions = {
        mode: 'no-cors',
        method: 'POST',
        headers: myHeaders,
        body: file,
        redirect: 'follow'
    };

    const resp = await fetch(`https://worker.uowt3.waiyanmt.com/${file.name}`, requestOptions)

}

export async function getPets() {
    const { data } = await axios.get('https://apn1-eternal-lemur-34403.upstash.io/get/pets?_token=AYZjACQgZjA5OTNkYTAtNzM5Mi00OGFhLWE2M2ItMjk4ZDNjZWUwZWNiMWIzZjczODA1YTJiNDc0Mzg1ODRkOGJkMjk4YzExNDE=')
    return JSON.parse(data.result) || []
}

async function updatePets(petObj) {
    const pets = await getPets()
    pets.push(petObj)
    const resp = axios.post("https://apn1-eternal-lemur-34403.upstash.io/set/pets?_token=AYZjACQgZjA5OTNkYTAtNzM5Mi00OGFhLWE2M2ItMjk4ZDNjZWUwZWNiMWIzZjczODA1YTJiNDc0Mzg1ODRkOGJkMjk4YzExNDE=",pets)
}



export default function ReleaseForm() {
    const handleForm = async (e) => {
        e.preventDefault()

        const owner = e.target[0].value
        const email = e.target[1].value
        const name = e.target[2].value
        const species = e.target[3].value
        const age = parseInt(e.target[4].value)
        const image = e.target[5].files[0]
        const diet = e.target[6].value
        const description = e.target[7].value



        try {

            await uploadImage(image)

            const obj = {
                owner, email, name, age, species,
                imageUrl: `https://worker.uowt3.waiyanmt.com/${image.name}`,
                diet, description
            }

            // store object data
            console.log(obj);

            await updatePets(obj)
            alert('Added Successfully!')
            window.location.reload()
        } catch (err) {
            alert('Something went wrong.')
        }

    }
    return (
        <>
            <div className='release'>
                <form onSubmit={handleForm} action="">
                    <h2>Release Form</h2>
                    <div className='col'>
                        <div className='row'>
                            <div>
                                <label htmlFor="">Owner</label>
                                <input required type="text" />
                            </div>
                            <div>
                                <label htmlFor="">Email</label>
                                <input required type="email" />
                            </div>
                        </div>
                        <div className='row'>
                            <div>
                                <label htmlFor="">Pet Name</label>
                                <input required type="text" />
                            </div>
                            <div>
                                <label htmlFor="">Pet Species</label>
                                <input required type="text" />
                            </div>
                        </div>
                        <div className='row'>
                            <div>
                                <label htmlFor="">Pet Age (Years)</label>
                                <input required type="number" />
                            </div>
                            <div>
                                <label htmlFor="">Pet Photo</label>
                                <input required type="file" />
                            </div>
                        </div>
                        <div className='row'>
                            <div>
                                <label htmlFor="">Pet Diet</label>
                                <input required type="text" />
                            </div>
                            <div>
                                <label htmlFor="">Special Needs</label>
                                <input required type="text" />
                            </div>
                        </div>
                    </div>
                    <button type="submit">Submit</button>
                </form>
            </div>
        </>
    )
}