import PetCard from "./PetCard"
import './browse.css'
import { getPets } from "./release-form"
import { useState, useEffect } from "react"

// const pets = [
//     {
//         name: 'Fluffy',
//         age: 3,
//         description: 'A playful and friendly dog',
//         color: 'Brown',
//         imageUrl: 'https://worker.uowt3.waiyanmt.com/fluffy.jpg',
//         species: 'Golden Retriever'
//     },
//     {
//         name: 'Whiskers',
//         age: 2,
//         description: 'A curious and affectionate cat',
//         color: 'White and gray',
//         imageUrl: 'https://worker.uowt3.waiyanmt.com/whiskers.jpg',
//         species: 'Snow Cat'
//     },
//     {
//         name: 'Bubbles',
//         age: 1,
//         description: 'An energetic and loving rabbit',
//         color: 'White',
//         imageUrl: 'https://worker.uowt3.waiyanmt.com/bubbles.jpg',
//         species: 'Rabbit'
//     },
//     {
//         name: 'Max',
//         age: 5,
//         description: 'A calm and gentle hamster',
//         color: 'Golden',
//         imageUrl: 'https://worker.uowt3.waiyanmt.com/max.jpg',
//         species: 'Hamster'
//     },
//     {
//         name: 'Sasha',
//         age: 4,
//         description: 'A majestic and proud parrot',
//         color: 'Green and yellow',
//         imageUrl: 'https://worker.uowt3.waiyanmt.com/sasha.jpg',
//         species: 'Parrot'
//     }

// ]


export function Search() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M9.538 15.23q-2.398 0-4.064-1.666T3.808 9.5q0-2.398 1.666-4.064t4.064-1.667q2.399 0 4.065 1.667q1.666 1.666 1.666 4.064q0 1.042-.369 2.017q-.37.975-.97 1.668l5.908 5.907q.14.14.15.345q.01.203-.15.363q-.16.16-.353.16q-.195 0-.354-.16l-5.908-5.908q-.75.639-1.725.989q-.975.35-1.96.35Zm0-1q1.99 0 3.361-1.37q1.37-1.37 1.37-3.36q0-1.99-1.37-3.36q-1.37-1.37-3.36-1.37q-1.99 0-3.361 1.37q-1.37 1.37-1.37 3.36q0 1.99 1.37 3.36q1.37 1.37 3.36 1.37Z"></path></svg>
    )
}



export default function Browse
() {
    const [pets, setPets] = useState([])
    const [filter, setFilter] = useState([])

    useEffect(() => {
        (async () => {
            const resp = await getPets()
            setPets(resp)
            setFilter(resp)
        })()
    },[])

    const handleChange = (e) => {
        setFilter(pets.filter((el)=> el.name.toLowerCase().includes(e.target.value)));
    }

    return (<>
        <div>
            <div className="search">
                <input type="text" onChange={handleChange} />   
                <Search />
            </div>
            <div className="petcontainer">
                {filter.map(({name,age,description,color,imageUrl,species},i) => {
                    return <PetCard key={i} name={name} age={age} description={description} color={color} imageUrl={imageUrl} species={species}/>
                })}
            </div>
        </div>
    </>)
}