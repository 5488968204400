export function Baseball(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}><g fill="none"><path d="M24 0v24H0V0h24ZM12.594 23.258l-.012.002l-.071.035l-.02.004l-.014-.004l-.071-.036c-.01-.003-.019 0-.024.006l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.016-.018Zm.264-.113l-.014.002l-.184.093l-.01.01l-.003.011l.018.43l.005.012l.008.008l.201.092c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.003-.011l.018-.43l-.003-.012l-.01-.01l-.184-.092Z"></path><path fill="currentColor" d="M12 2a9.96 9.96 0 0 1 6.5 2.4a10.047 10.047 0 0 0-2.096 2.488l-.044-.02a1 1 0 1 0-.846 1.812l.043.02a9.941 9.941 0 0 0-.508 2.3H15a1 1 0 1 0 0 2h.05a9.97 9.97 0 0 0 .507 2.3l-.043.02a1 1 0 1 0 .846 1.812l.044-.02c.56.94 1.27 1.78 2.096 2.488A9.96 9.96 0 0 1 12 22a9.96 9.96 0 0 1-6.5-2.4a10.05 10.05 0 0 0 2.096-2.488l.044.02a1 1 0 1 0 .846-1.812l-.043-.02A9.944 9.944 0 0 0 8.95 13H9a1 1 0 1 0 0-2h-.05a9.946 9.946 0 0 0-.507-2.3l.043-.02a1 1 0 0 0-.846-1.813l-.044.021c-.56-.94-1.27-1.78-2.096-2.488A9.96 9.96 0 0 1 12 2ZM4.115 5.849A8.042 8.042 0 0 1 5.773 7.74a1 1 0 0 0 .845 1.81c.15.466.258.952.32 1.452a1 1 0 0 0 0 1.996c-.062.5-.17.986-.32 1.453a1 1 0 0 0-.845 1.809a8.041 8.041 0 0 1-1.658 1.891A9.957 9.957 0 0 1 2 12c0-2.32.79-4.455 2.115-6.151Zm15.77 0A9.957 9.957 0 0 1 22 12c0 2.32-.79 4.455-2.115 6.151a8.043 8.043 0 0 1-1.658-1.891a1 1 0 0 0-.845-1.81a7.951 7.951 0 0 1-.32-1.452a1 1 0 0 0 0-1.996c.062-.5.17-.986.32-1.453a1 1 0 0 0 .845-1.809a8.043 8.043 0 0 1 1.658-1.891Z"></path></g></svg>
    )
}


export function Heartbeat(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}><g fill="none"><path d="M24 0v24H0V0h24ZM12.594 23.258l-.012.002l-.071.035l-.02.004l-.014-.004l-.071-.036c-.01-.003-.019 0-.024.006l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.016-.018Zm.264-.113l-.014.002l-.184.093l-.01.01l-.003.011l.018.43l.005.012l.008.008l.201.092c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.003-.011l.018-.43l-.003-.012l-.01-.01l-.184-.092Z"></path><path fill="currentColor" d="m9.93 10.376l2.15 5.018a1 1 0 0 0 1.679.257l.073-.096L15.535 13h5.907c-1.915 4.738-8.165 8-9.442 8c-1.25 0-7.27-3.127-9.316-7.706L2.558 13H8a1 1 0 0 0 .832-.445l.062-.108l1.036-2.071ZM12 4.339c2.072-1.686 4.534-1.68 6.494-.538c2.095 1.221 3.569 3.7 3.504 6.592l-.014.305l-.027.302H15a1 1 0 0 0-.757.346l-.075.1l-.976 1.463l-2.273-5.303a1 1 0 0 0-1.749-.164l-.064.11L7.382 11h-5.34a6.847 6.847 0 0 1-.04-.607c-.065-2.892 1.409-5.37 3.504-6.592C7.466 2.66 9.928 2.653 12 4.34Z"></path></g></svg>
    )
}


export function Grooming(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}><g fill="none"><path d="M24 0v24H0V0h24ZM12.594 23.258l-.012.002l-.071.035l-.02.004l-.014-.004l-.071-.036c-.01-.003-.019 0-.024.006l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.016-.018Zm.264-.113l-.014.002l-.184.093l-.01.01l-.003.011l.018.43l.005.012l.008.008l.201.092c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.003-.011l.018-.43l-.003-.012l-.01-.01l-.184-.092Z"></path><path fill="currentColor" d="M7.032 14.136a1 1 0 0 1 .707 1.225l-1.553 5.796a1 1 0 0 1-1.932-.518l1.553-5.795a1 1 0 0 1 1.225-.708Zm10.5 0a1 1 0 0 1 .707 1.225l-1.553 5.796a1 1 0 1 1-1.932-.518l1.553-5.795a1 1 0 0 1 1.225-.708Zm-7 0a1 1 0 0 1 .707 1.225l-1.553 5.796a1 1 0 0 1-1.932-.518l1.553-5.795a1 1 0 0 1 1.225-.707Zm2.275.708a1 1 0 0 1 1.956.403l-.024.114l-1.553 5.796a1 1 0 0 1-1.955-.404l.023-.114l1.553-5.795ZM11.5 2a6.502 6.502 0 0 1 6.086 4.212a6.002 6.002 0 0 1 2.43 10.246l.155-.58a3 3 0 0 0-4.647-3.213a2.994 2.994 0 0 0-3.5 0a2.994 2.994 0 0 0-3.5 0a3 3 0 0 0-4.648 1.66l-.553 2.063A5 5 0 0 1 5 8.416A6.5 6.5 0 0 1 11.5 2Z"></path></g></svg>
    )
}


export function FindingHome(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}><g fill="none" fillRule="evenodd"><path d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01l-.184-.092Z"></path><path fill="currentColor" d="M10.772 2.688a2 2 0 0 1 2.456 0l8.384 6.52c.753.587.337 1.792-.615 1.792H20v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-8h-.997c-.953 0-1.367-1.206-.615-1.791l8.384-6.52Z"></path></g></svg>
    )
}