import './petcard.css'

export default function PetCard({name,age,description,color,imageUrl,species}) {
    return(
        <>
        <div className="petcard">
            <div>
                <img src={imageUrl} alt="" />
            </div>
            <div>
                <h3>{name}</h3>
                <p>Age: {age} years</p>
                <p>Species: {species}</p>
                <p>{description}</p>
            </div>
            <div>
                <BiChevronUp />
                <BiChevronDown />
            </div>
        </div>
        </>
    )
}

export function BiChevronUp(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16" {...props}><path fill="currentColor" fillRule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"></path></svg>
    )
}



export function BiChevronDown(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16" {...props}><path fill="currentColor" fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"></path></svg>
    )
}