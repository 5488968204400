import './footer.css'

export default function Footer() {
    return (
        <footer>
            <div>
                <img src="" alt="" />
            </div>
            <div>
                <a href="/">Home</a>
                <a href="/about">About</a>
                <a href="/faq">FAQ</a>
                <a href="">Contact Us</a>
            </div>
            <hr />
            <p>©2023 Pet Heaven All Rights Reserved</p>
        </footer>
    )
}