import './servicecard.css'

export default function ServiceCard({icon,caption}) {
    return (
        <div className='servicecard'>
            {icon}
            <span>{caption}</span>
        </div>
    )
}

